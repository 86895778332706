import React, { Component }  from 'react';
import Carousel from 'react-bootstrap/Carousel';

function DarkVariantExample() {
  return (
    <Carousel data-bs-theme="dark">
      <Carousel.Item>
        <img style={{maxWidth:'100vw', height:'80vh'}}
          className="d-block w-100"
          src="/assets/images/blog/blog-05.jpeg"
          alt="First slide"
        />
       
      </Carousel.Item>
      <Carousel.Item>
        <img 
          className="d-block w-100"
          src="/assets/images/blog/blog-06.jpeg"
          alt="Second slide"
        />
      
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="/assets/images/blog/blog-07.jpeg"
          alt="Third slide"
        />
       
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="/assets/images/blog/blog-08.jpeg"
          alt="Third slide"
        />
       
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="/assets/images/blog/blog-09.jpeg"
          alt="Third slide"
        />
       
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="/assets/images/blog/blog-10.jpeg"
          alt="Third slide"
        />
       
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="/assets/images/blog/blog-11.jpeg"
          alt="Third slide"
        />
       
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="/assets/images/blog/blog-12.jpeg"
          alt="Third slide"
        />
       
      </Carousel.Item>
    </Carousel>
  );
}

export default DarkVariantExample;