import React, { Component } from "react";

class AboutTwo extends Component{
    render(){
        let title = 'About',
        description = 'Welcome to White Eagle Limo Service, '
        +'the premier provider of luxury transit and limousine services in Chicago. '
        +'Celebrating over 15 years at the pinnacle of the industry, we have consistently '
        +'merged top-tier service with unparalleled personal driver convenience for the entire '
        +'state of Illinois. Our journey has been a continuous learning experience, '
        +'teaching us that every event is unique and equally significant. '
        +'Recognizing this, we avoid a generic, one-size-fits-all approach. '
        +'Rather, we collaborate closely with our clients to design custom-tailored '
        +'transport packages that accommodate their distinct requirements. At White Eagle Limo, '
        +'our primary focus is to provide high-quality services at reasonable prices. Our diverse '
        +'fleet of luxury transportation vehicles ranges from exquisite sedans to spacious sprinter '
        +'vans, ensuring that we are equipped to cater to our clients\' various needs. '
        +'We are the preferred choice for businesses and individuals who expect nothing less than '
        +'efficient, safe, and exceptional car services. Our mission is to offer a dependable and '
        +'outstanding chauffeur service that turns your special occasions into unforgettable experiences. '
        +'We stand by our professional integrity and are dedicated to offering personalized limousine transportation '
        +'services that exceed expectations. '
        +'In addition to our exclusive services, we also handle limousine transfers from all '
        +'airports, offering city tours and transport to special events. Trust White Eagle Limo Service '
        +'to deliver an unrivaled service that ensures every journey is as seamless and comfortable as possible, '
        +'making your every occasion a truly memorable one.';
                return(
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-center">

                            <div className="col-lg-5 col-md-12">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/imageAbout.jpeg" alt="About Images"/>
                                </div>
                            </div>

                            <div className="col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">{title}</h2>
                                        <p className="description">{description}</p>
                                    </div>

                                    <div className="rn-form-group">
                <a className="rn-button-style--2 btn-solid" href="/about" value="Go to About Us" name="submit" id="mc-embedded-subscribe">Go to About Us</a>
            </div> 
                                    {/* <div className="row mt--30 mt_sm--10">
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                                <h3 className="title">Who we are</h3>
                                                <p>There are many vtions of passages of Lorem Ipsum available, but the majority have suffered.</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                                <h3 className="title">Who we are</h3>
                                                <p>There are many vtions of passages of Lorem Ipsum available, but the majority have suffered.</p>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default AboutTwo;