import React, { useState } from 'react';
import styled from 'styled-components';

// Styled components for the CSS
const Main = styled.main`
  /*max-width: 1200px;*/
  margin: 0 auto;
  padding: 50px 0;

  h1 {
    margin-bottom: 50px;
  }
`;

const Wrapper = styled.div`
  position: relative;
  margin-left: -45px;

 /* &::after {
    position: absolute;
    top: 0;
    z-index: 1;
    content: '';
    display: block;
    width: 200px;
    height: 100%;
  } */

  &::before {


  }

  &::after {


  }
`;

const Items = styled.ul`
  position: relative;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  font-size: 0;
  cursor: pointer;

  &.active {
    cursor: grab;
  }




`;
const Image = styled.img`

@media screen and (max-width: 500px) {
    width: 98vw;
    height: 350px;
  }

  @media screen and (min-width: 800px) {
    width: 400px;
    height: 400px;
  }

  @media screen and (min-width: 1200px) {
    width: 400px;
    height: 400px;

`;
const Item = styled.li`
  display: inline-block;
  margin-left: 10px;
  user-select: none;
  background: tomato;
  width: 400px;
  height: 400px;
  color: #222;
  font-size: 33px;
  font-weight: bold;
  line-height: 130px;

  &:last-child {
    margin-right: 10px;
  }

  @media screen and (max-width: 500px) {
    width: 98vw;
    height: 350px;
  }

  @media screen and (min-width: 800px) {
    width: 400px;
    height: 400px;
  }

  @media screen and (min-width: 1200px) {
    width: 400px;
    height: 400px;
  }
`;
const items = [
    {id:1 , src:'/assets/images/blog/blog-10.jpeg'},
    {id:2 , src:'/assets/images/blog/blog-12.jpeg'},
    {id:3 , src:'/assets/images/blog/blog-09.jpeg'},
    {id:4 , src:'/assets/images/blog/blog-11.jpeg'},
    {id:5 , src:'/assets/images/blog/blog-07.jpeg'},
    {id:6 , src:'/assets/images/blog/blog-06.jpeg'},
    {id:7 , src:'/assets/images/blog/blog-08.jpeg'},
    {id:8 , src:'/assets/images/blog/blog-05.jpeg'},



  ];


const ScrollableCarousel = ({zoomImage}) => {
  const [isDown, setIsDown] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const end = () => {
    setIsDown(false);
    sliderRef.current.classList.remove('active');
  };
//   const zoomImage = (e)=>
//   {
//     e.preventDefault()
//     zoomImage(e.target.src)
//       //this.setState({isOpen: true, src : src})
//       //window.zoom(src)
//       console.log(e.target.src+" clicked")
//   }
  const start = (e) => {
    setIsDown(true);
    sliderRef.current.classList.add('active');
    const x = e.pageX || (e.touches[0] && e.touches[0].pageX) - sliderRef.current.offsetLeft;
    setStartX(x);
    setScrollLeft(sliderRef.current.scrollLeft);
  };

  const move = (e) => {
    if (!isDown) return;
    e.preventDefault();
    const x = e.pageX || (e.touches[0] && e.touches[0].pageX) - sliderRef.current.offsetLeft;
    const dist = x - startX;
    sliderRef.current.scrollLeft = scrollLeft - dist;
  };

  const sliderRef = React.createRef();

  return (
    <Main>

      <Wrapper>
        <Items
          className={isDown ? 'active' : ''}
          ref={sliderRef}
          onMouseDown={start}
          onTouchStart={start}
          onMouseMove={move}
          onTouchMove={move}
          onMouseLeave={end}
          onMouseUp={end}
          onTouchEnd={end}
        >
          {items.map((item, index) => (
            <Item key={index}><Image src={item.src} style={{}} onClick={()=>zoomImage(item.src)}/></Item>
          ))}
        </Items>
      </Wrapper>
    </Main>
  );
};

export default ScrollableCarousel;