import React, { Component } from "react";
import { FiCast , FiLayers , FiUsers , FiMonitor } from "react-icons/fi";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const ServiceList = [
    {
        icon: <FiCast />,
        index: 1,
        value : 'https://windycitylimos.com/wp-content/uploads/2020/05/030-board-meeting.svg',
        title: 'Corporate Transportation',
        query : '/service-details?id=1',
        description: 'Travel with poise and professionalism. Our corporate transportation service ensures you arrive on-time and in style, making a lasting impression at your next business meeting or event.'
    },
    {
        icon: <FiLayers />,
        value: 'https://windycitylimos.com/wp-content/uploads/2020/05/026-bell.svg',
        index: 2,
        title: 'Wedding Transportation Chicago',
        query : '/service-details?id=2',
        description: 'Add a touch of elegance to your special day. Our wedding transportation service provides a seamless, luxurious ride, ensuring a fairy-tale touch from start to finish.'
    },
    {
        icon: <FiUsers />,
        index: 3,
        value: 'https://windycitylimos.com/wp-content/uploads/2020/05/025-tent.svg',
        title: 'Group & Event Charters',
        query : '/service-details?id=3',
        description: 'Transportation for any event, any size. Our group and event charters ensure your entire party travels together comfortably, arriving at your destination in sync and style.'
    },
    {
        icon: <FiMonitor />,
        value:'https://windycitylimos.com/wp-content/uploads/2020/05/031-airplane-1.svg',
        index: 4,
        title: 'Airport Rides',
        query : '/service-details?id=4',
        description: 'Say goodbye to airport stress. Our reliable airport rides guarantee timely arrivals and comfortable transfers, allowing you to sit back, relax, and enjoy the journey.'
    },
    {
        icon: <FiMonitor />,
        index: 5,
        value:'https://windycitylimos.com/wp-content/uploads/2020/05/010-enterprise.svg',
        title: 'Employee Transportation',
        query : '/service-details?id=5',
        description: 'Safe, reliable, and efficient – our employee transportation service ensures your team arrives punctually and ready to take on the day. Invest in their comfort and productivity with us.'
    },
    {
        icon: <FiMonitor />,
        index: 6,
        value:'https://windycitylimos.com/wp-content/uploads/2020/05/032-passport.svg',
        title: 'Global Transfers',
        query : '/service-details?id=6',
        description: 'Wherever your journey takes you, we\'re there. Our global transfer services connect you to major cities around the world, providing consistent, high-quality service across borders.'
    }
]

class ServiceTwo extends Component{
    render(){
        let title = 'Services',
        description = 'Delivering a range of premium transportation solutions, '
        +'our services include corporate travel, wedding charters, group & event transportation, reliable airport transfers,'
        +' employee commuting, and global rides. With a commitment to punctuality, comfort, and style, we make your '
        + 'journey seamless, whether it\'s a professional engagement, a special celebration, or an international expedition.';
        return(
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-4 col-12">
                        <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
                            <h2 className="title">{title}</h2>
                            <p>{description}</p>
                            <div className="service-btn">
                                <a className="btn-transparent rn-btn-dark" href="/service"><span className="text">Request Custom Service</span></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 col-12 mt_md--50">
                        <div className="row service-one-wrapper">
                            {ServiceList.map( (val , i) => (
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                                    {/* <Link to="/service-details" state={{ title: "hello" }}> */}
                                    <a href={val.query}>
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                <img src={val.value} width='50px'/>
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                        {/* </Link> */}
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceTwo;
