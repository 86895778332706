import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from 'react-modal-video';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderFive";
import Footer from "../component/footer/Footer";
import FooterTwo from "../component/footer/FooterTwo";
import CallAction from "./callaction/CallAction";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const ServiceList = [
    {
        icon: '',
        index: 1,
        value : 'https://windycitylimos.com/wp-content/uploads/2020/05/030-board-meeting.svg',
        title: 'Corporate Transportation',
        description: 'Experience a seamless blend of style, punctuality, and professionalism with our Corporate '
        +'Transportation service. We understand that in the world of business, time is money, and making a strong '
        +'first impression can be crucial. That\'s why our service is designed to ensure you not only arrive on time, '
        +'but also in style, ready to command attention and respect at your next business meeting or event. With our '
        +'modern, well-maintained fleet, you\'re guaranteed a smooth, comfortable ride that reflects your professional image.'
        +' Beyond just a ride, our Corporate Transportation service is a complete mobility solution for today\'s professionals.'
        +' We offer the convenience of advance booking and real-time tracking, ensuring you have control over your travel '
        +' itinerary. Our professional chauffeurs, trained in etiquette and discretion, provide an exemplary service that '
        +'extends beyond driving. They are committed to creating an environment where you can prepare, relax, or strategize'
        +' before your important meeting or event.'
        +' The level of attention to detail we provide demonstrates our commitment to your satisfaction. We value your '
        +' time and strive to provide a service that exceeds your expectations. No matter the destination, '
        +' our Corporate Transportation service guarantees you arrive prepared, relaxed, and ready to take on your business endeavors.'
    },
    {
        icon: '',
        value: '',
        index: 2,
        title: 'Wedding Transportation Chicago',
        description: 'Celebrate your special day with a touch of elegance and tranquility by choosing our Wedding Transportation service. We understand how vital every detail is for your dream wedding, and we strive to provide a seamless, luxurious journey from start to finish. Our stylish and comfortable vehicles are perfect for adding that fairy-tale touch to your celebration, ensuring that you arrive in grand style and on time for your ceremony. In the second phase of our service, we offer custom decor options for our vehicles to align with your wedding theme, adding that extra sparkle to your event. Moreover, we facilitate seamless coordination with wedding planners, photographers, and event venues, ensuring every transport-related detail is handled professionally. Our vehicles are not just modes of transportation, but a part of the beautiful canvas that makes your wedding day unforgettable. Our professional chauffeurs are trained to ensure your comfort, privacy, and punctuality, making your wedding day transportation worry-free. With personalized attention to cater to your unique needs and a commitment to excellent service, we aim to make your journey to wedded bliss as magical as the occasion itself. Choose our Wedding Transportation service for a fairy-tale ride on your most special day.'
    },
    {
        icon: '',
        index: 3,
        value: 'https://windycitylimos.com/wp-content/uploads/2020/05/025-tent.svg',
        title: 'Group & Event Charters',
        description: 'Whether it\'s a convention, concert, or a group outing, our Group & Event Charters are designed to ensure that your entire party travels together comfortably, safely, and punctually. We cater to groups of all sizes, offering a stress-free solution to your transportation needs. With our well-maintained fleet, we guarantee your group\'s coordinated arrival at any event, enhancing your overall experience. As part of our service, we offer personalized routing and flexible scheduling to best accommodate your event\'s requirements. We understand that group travel can be complicated, so our team is here to handle all the details, allowing you to focus on the event itself. No matter the size of your group or the nature of your event, we make sure every guest enjoys a comfortable, safe, and punctual ride. Our Group & Event Charters are more than just transportation; they\'re an integral part of your event experience. From the moment you book with us, our professional staff will work closely with you to plan and execute your transportation details flawlessly. Experience the convenience of synchronized travel, and let us add an extra touch of comfort and style to your event.'
    },
    {
        icon: '',
        value:'',
        index: 4,
        title: 'Airport Rides',
        description: 'Bidding goodbye to airport stress has never been easier with our reliable Airport Rides. We guarantee timely pickups and comfortable transfers, allowing you to sit back, relax, and enjoy the journey. No more rushing for taxis or worrying about parking - just a hassle-free ride that gets you to your destination on time. We take it a step further by offering flight tracking services, which allow us to adjust your pickup time in case of early arrival or flight delay. This means you can rest easy, knowing that your ride will be ready and waiting when you land. In addition, our spacious vehicles provide ample room for luggage, ensuring you and your belongings reach your destination securely. Our Airport Rides are not just about transfers, they\'re about providing a smooth travel experience. Our chauffeurs are trained to maintain the highest levels of professionalism, ensuring your comfort and safety during your journey. With our Airport Rides, travel isn\'t just about the destination, but also the journey itself. Trust us with your next airport transfer and transform travel stress into travel bliss.'
    },
    {
        icon: '',
        index: 5,
        value:'https://windycitylimos.com/wp-content/uploads/2020/05/010-enterprise.svg',
        title: 'Employee Transportation',
        description: 'Invest in the comfort, productivity, and safety of your team with our Employee Transportation service. We understand the importance of punctuality and the role of a comfortable commute in employee satisfaction and productivity. Our service ensures a safe, reliable, and efficient commute for your team, allowing them to arrive at work ready to take on the day. Our transportation service caters to the unique needs of your organization. With flexible scheduling and route planning, we accommodate varying shift times and multiple locations, ensuring that your employees\' commutes are as effortless as possible. We\'re committed to contributing to a more productive, satisfied, and engaged workforce by providing reliable and comfortable transportation. Our Employee Transportation service is not merely a commute solution; it\'s an extension of your company\'s commitment to its employees\' well-being. We ensure a comfortable and smooth ride with professional chauffeurs and well-maintained vehicles. With us handling the commute, your employees can focus on what they do best - contributing their skills and talent to your organization\'s success.'

    },
    {
        icon: '',
        index: 6,
        value:'https://windycitylimos.com/wp-content/uploads/2020/05/032-passport.svg',
        title: 'Global Transfers',
        description: 'Reach beyond borders with our Global Transfers service. We connect you to major cities around the world, providing a consistent, high-quality travel experience. Whether you\'re traveling for business or pleasure, our service ensures you arrive at your destination comfortably and in style.In this globally connected world, we understand the need for a reliable transportation partner who can cater to your international travel needs. That\'s why we collaborate with trusted partners worldwide to ensure a seamless transfer experience across borders. With us, you can rest assured that the high-quality service you experience domestically extends to your international travels.Our Global Transfers aren\'t just about international rides; they\'re about providing a seamless travel experience, no matter where you are. We work closely with trusted partners worldwide to maintain the highest standards of service, ensuring your comfort, safety, and punctuality. With our Global Transfers, your journeys across borders are just as comfortable, reliable, and stress-free as those at home.'
    }
]

class ServiceDetails extends Component{
    
    constructor (props) {
        
        super(props);
        this.state = {
          isOpen: false,
          title:'',
          info:'',
          headText:''
        }
        this.openModal = this.openModal.bind(this)
    }

componentDidMount(){
    var location = window.location.search;

    var query = location;
    if(query.includes("1"))
    {
    
        var obj = ServiceList.find(a=>a.index == 1);
        this.setState({title : obj.title, headText: obj.title, info: obj.description})
    }
    else if(query.includes("2"))
    {
        var obj = ServiceList.find(a=>a.index == 2);
        this.setState({title : obj.title, headText: obj.title, info: obj.description})
    }
    else if(query.includes("3"))
    {
        var obj = ServiceList.find(a=>a.index ==3);
        this.setState({title : obj.title, headText: obj.title, info: obj.description})
    }
    else if(query.includes("4"))
    {
        var obj = ServiceList.find(a=>a.index == 4);
        this.setState({title : obj.title, headText: obj.title, info: obj.description})
    }
    else if(query.includes("5"))
    {
        var obj = ServiceList.find(a=>a.index == 5);
        this.setState({title : obj.title, headText: obj.title, info: obj.description})
    }
    else if(query.includes("6"))
    {
        var obj = ServiceList.find(a=>a.index == 6);
        this.setState({title : obj.title, headText: obj.title, info: obj.description})
    }
}
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>
                
                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle='Service Details' />
                {/* End Pagehelmet  */}

                <Header headerPosition="header--static logoresize" headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--5"  data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">{this.state.headText}</h2>
                                    {/* <p>Fresh From The Press Discription</p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}


                {/* Start About Area  */}
                <div className="rn-about-area ptb--120 bg_color--1">
                    <div className="rn-about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                {/* <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img className="w-100" src="https://i0.wp.com/windycitylimos.com/wp-content/uploads/2020/05/22339295_1802573469776078_7216555973195547604_o.jpg?w=790&ssl=1" alt="About Images"/>
                                    </div>
                                </div> */}
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title">{this.state.title}</h2>
                                            <p className="description">{this.state.info}</p>
                                        </div>
                                        {/* <div className="row mt--30">
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h3 className="title">Who we are</h3>
                                                    <p>There are many vtions of passages of Lorem Ipsum available, but the majority have suffered.</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h3 className="title">Who we are</h3>
                                                    <p>There are many vtions of passages of Lorem Ipsum available, but the majority have suffered.</p>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area  */}

                {/* Start Page Wrapper */}
                {/* <div className="rn-service-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner"> */}
                                        {/* Start Single Area */}
                                        {/* <div className="row sercice-details-content pb--80 align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="thumb">
                                                    <img className="w-100" src="/assets/images/service/service-01.png" alt="Service Images"/>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <p>but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum. You need to be sure there isn't anything embarrassing</p>
                                                    <p>hidden in the middle of text. All the Lorem Ipsum generators tend toitrrepeat predefined chunks. Necessary, making this the first true generator on the Internet.</p>
                                                    <h4 className="title">Proceess of metel</h4>
                                                    <ul className="liststyle">
                                                        <li>Yet this above sewed flirted opened ouch</li>
                                                        <li>Goldfinch realistic sporadic ingenuous</li>
                                                        <li>Abominable this abidin far successfully then like piquan</li>
                                                        <li>Risus commodo viverra</li>
                                                        <li>Lorem ipsum dolor sit amet, consectetur adipiscing</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div> */}
                                        {/* End Single Area */}

                                        {/* Start Single Area */}
                                        {/* <div className="row sercice-details-content align-items-center">
                                            <div className="col-lg-6 col-12 order-2 order-lg-1">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <p>but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum. You need to be sure there isn't anything embarrassing</p>
                                                    <p>hidden in the middle of text. All the Lorem Ipsum generators tend toitrrepeat predefined chunks. Necessary, making this the first true generator on the Internet.</p>
                                                    <h4 className="title">Our Working Process</h4>
                                                    <ul className="liststyle">
                                                        <li>Yet this above sewed flirted opened ouch</li>
                                                        <li>Goldfinch realistic sporadic ingenuous</li>
                                                        <li>Abominable this abidin far successfully then like piquan</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12 order-1 order-lg-2">
                                                <div className="thumb position-relative">
                                                    <img className="w-100" src="/assets/images/service/service-02.png" alt="Service Images"/>
                                                    <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='ZOoVOfieAF8' onClose={() => this.setState({isOpen: false})} />
                                                    <button className="video-popup" onClick={this.openModal}><span className="play-icon"></span></button>
                                                </div>
                                            </div>
                                        </div> */}
                                        {/* End Single Area */}

                                    {/* </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* End Page Wrapper */}
                
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                <CallAction/>
                <FooterTwo />

            </React.Fragment>
        )
    }
}
export default ServiceDetails;