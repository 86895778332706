import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import { FiCast , FiLayers , FiUsers , FiMonitor ,FiChevronUp } from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
import Header from "../component/header/HeaderFive";
import Footer from "../component/footer/Footer";
import ServiceTwo from "./service/ServiceTwo";
import CallAction from "./callaction/CallAction";
import FooterTwo from "../component/footer/FooterTwo";


const ServiceList = [
    {
        icon: 'https://windycitylimos.com/wp-content/uploads/2020/05/030-board-meeting.svg',
        title: 'Corporate Transportation',
        description: 'Travel with poise and professionalism. Our corporate transportation service ensures you arrive on-time and in style, making a lasting impression at your next business meeting or event.'
    },
    {
        icon: <FiLayers />,
        title: 'Wedding Transportation Chicago',
        description: 'Add a touch of elegance to your special day. Our wedding transportation service provides a seamless, luxurious ride, ensuring a fairy-tale touch from start to finish.'
    },
    {
        icon: <FiUsers />,
        title: 'Group & Event Charters',
        description: 'Transportation for any event, any size. Our group and event charters ensure your entire party travels together comfortably, arriving at your destination in sync and style.'
    },
    {
        icon: <FiMonitor />,
        title: 'Airport Rides',
        description: 'Say goodbye to airport stress. Our reliable airport rides guarantee timely arrivals and comfortable transfers, allowing you to sit back, relax, and enjoy the journey.'
    },
    {
        icon: <FiMonitor />,
        title: 'Employee Transportation',
        description: 'Safe, reliable, and efficient – our employee transportation service ensures your team arrives punctually and ready to take on the day. Invest in their comfort and productivity with us.'
    },
    {
        icon: <FiMonitor />,
        title: 'Global Transfers',
        description: 'Wherever your journey takes you, we\'re there. Our global transfer services connect you to major cities around the world, providing consistent, high-quality service across borders.'
    }
]
class Service extends Component{
    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Service' />
                <Header headerPosition="header--fixed logoresize" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <Breadcrumb title={'Service'}   />
                {/* End Breadcrump Area */}


                 {/* Start Service Area  */}
                 <div className="service-area ptb--80  bg_image bg_image--3">
                    <div className="container">
                        <ServiceTwo />
                    </div>
                </div>
                {/* End Service Area  */}

                {/* Start Service Area */}
                {/* <div className="service-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>Digital Marketing</h2>
                                    <p>There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row service-one-wrapper">
                            {ServiceList.map( (val , i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                    <a href="/service-details">
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div> */}
                {/* End Service Area */}

                {/* Start Service Area */}
                {/* <div className="service-area ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>Strategy</h2>
                                    <p>There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row service-one-wrapper">
                            {ServiceList.map( (val , i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                    <a className="text-center" href="/service-details">
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div> */}
                {/* End Service Area */}

                {/* Start Service Area */}
                {/* <div className="service-area creative-service-wrapper pt--90 pb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>Creative Agency</h2>
                                    <p>There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row creative-service">
                            {ServiceList.map( (val , i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                    <a href="/service-details">
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div> */}
                {/* End Service Area */}

                {/* Start Service Area */}
                {/* <div className="service-area creative-service-wrapper pt--90 pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>Development</h2>
                                    <p>There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row creative-service">
                            {ServiceList.map( (val , i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                    <a className="text-center" href="/service-details">
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div> */}
                {/* End Service Area */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <CallAction/>
                <FooterTwo />


            </React.Fragment>
        )
    }
}
export default Service;